ul li {
  font-size: 15px;
}
span {
  font-weight: bold;
  font-size: 22px;
}
a {
  color: #0d6efd;
}
