html {
  overflow-x: hidden;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.6;
  color: #292b2c;
  overflow-x: hidden;
}

a {
  transition: all 0.5s ease;
  text-decoration: none;
  color: #292b2c;
}

img,
video {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #292b2c;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.67;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    span {
      display: inline-block;
      vertical-align: top;
      width: 90%;
    }
  }
}

.text-right {
  text-align: right;
}

.flex-col {
  flex-direction: column;
}

.flex-md-row {
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.col-xxl-20 {
  @media (min-width: 1400px) {
    width: 20%;
  }
}

.swiper {
  width: 100%;
}

.pagination {
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;

  li {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      margin: 0 0.125rem !important;
      background-color: transparent;
      border: 2px solid #ad203e;
      border-radius: 5px !important;
      padding: 0 5px;
      min-width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-size: 13px;
      &,
      &:visited {
        color: #ad203e !important;
      }
      &:hover {
        background: #ad203e;
        color: #ffffff !important;
      }

      @media (min-width: 768px) {
        min-width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
      }
    }

    &.selected {
      a {
        background: #ad203e;
        color: #ffffff !important;
      }
    }
  }
}
.customSlider {
  /* max width of your slider */
  max-width: 400px;
  max-width: 95%;
  /* Optional: Only If you want to center your slider in the page */
  margin: auto;
  color: white;
  background-color: white;
  height: 8px;
}

.customSlider-track {
  /* Top value to align your track to the center of your thumb */
  /* thickness of the track */
  height: 8px;
  /* default color of your track */
  background: #4d4c4c;
}

.customSlider-track.customSlider-track-0 {
  /* color of the track before the thumb */
  //background: var(--main-color);
  background: linear-gradient(to right, rgba(230, 51, 42, 0.5), rgba(230, 51, 42, 0.7), rgb(230, 51, 42));
}
.customSlider-markClassName {
  width: 8px;
  height: 8px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
// .customSlider-thumbActiveClassName {
//   width: 16px;
//   height: 16px;
//   background-color: rgb(230, 51, 42);
//   cursor: pointer;
//   border-radius: 50%;
//   vertical-align: middle;
// }
